import React from 'react';
import { get } from 'lodash';
import { graphql } from 'gatsby';
import { RichText } from '@components/shared';
import Image from '@components/image';
import { formatDate, formatTime } from '@src/helpers';
import {
    DateWrapper,
    EventPageWrapper,
    InfoWrapper,
    Lineup,
    LinkIcon,
    Links,
    LocationTitle,
    LocationAddress,
    PosterWrapper,
    PosterPlaceholder,
    Section,
    SectionContent,
    SectionHeader,
    Tickets,
    TitleWrapper,
} from './event.styled';
import {} from '../info.styled';

const EventPage = ({ data, pageContext }) => {
    if (!data) {
        return null;
    }

    const {
        prismic: {
            event: {
                date_start,
                date_end,
                info,
                lineup,
                links,
                location_address,
                location_title,
                tickets_link,
                title,
                poster_image,
                poster_imageSharp,
            },
        },
    } = data;

    return (
        <EventPageWrapper>
            <Section className="sec-title">
                <SectionHeader>event name</SectionHeader>
                <SectionContent>
                    <TitleWrapper>
                        {title && title.length ? (
                            <RichText render={title} />
                        ) : null}
                    </TitleWrapper>
                </SectionContent>
            </Section>
            <Section className="sec-date">
                <SectionHeader>date</SectionHeader>
                <SectionContent>
                    <DateWrapper>
                        {date_start && date_end ? (
                            <div>
                                <div>{formatDate(date_start)}</div>
                                <div>
                                    {formatTime(date_start)}-
                                    {formatTime(date_end)}
                                </div>
                            </div>
                        ) : null}
                    </DateWrapper>
                </SectionContent>
            </Section>
            <Section className="sec-location">
                <SectionHeader>location</SectionHeader>
                <SectionContent>
                    {location_title && location_title.length ? (
                        <LocationTitle>
                            <RichText render={location_title} />
                        </LocationTitle>
                    ) : null}
                    {location_address && location_address.length ? (
                        <LocationAddress>
                            <RichText render={location_address} />
                        </LocationAddress>
                    ) : null}
                </SectionContent>
            </Section>
            <Section className="sec-lineup">
                <SectionHeader>line-up</SectionHeader>
                <SectionContent>
                    {lineup && lineup.length ? (
                        <Lineup>
                            <RichText render={lineup} />
                        </Lineup>
                    ) : null}
                </SectionContent>
            </Section>
            <Section className="sec-links">
                <SectionHeader>links</SectionHeader>
                <SectionContent>
                    {links && links.length ? (
                        <Links>
                            {links.map((l, i) => (
                                <div key={`event_link_${i}`}>
                                    <LinkIcon>
                                        <span role="img" aria-label="link-icon">
                                            &#128279;
                                        </span>
                                    </LinkIcon>
                                    <a href={get(l, 'link_url.url', '#')}>
                                        {l.link_title}
                                    </a>
                                </div>
                            ))}
                        </Links>
                    ) : null}
                </SectionContent>
            </Section>
            <Section className="sec-dummy" />
            <Section className="sec-tickets">
                <SectionHeader>tickets</SectionHeader>
                <SectionContent>
                    {tickets_link && tickets_link.url ? (
                        <Tickets>
                            <LinkIcon>
                                <span role="img" aria-label="link-icon">
                                    &#128279;
                                </span>
                            </LinkIcon>
                            <a href={tickets_link.url}>buy here</a>
                        </Tickets>
                    ) : null}
                </SectionContent>
            </Section>
            <Section className="sec-info">
                <SectionHeader>info</SectionHeader>
                <SectionContent>
                    {info && info.length ? (
                        <InfoWrapper>
                            <RichText render={info} />
                        </InfoWrapper>
                    ) : null}
                </SectionContent>
            </Section>
            <Section className="sec-poster">
                <PosterWrapper>
                    {poster_image ? (
                        <Image
                            image={poster_image}
                            imageSharp={poster_imageSharp}
                        />
                    ) : (
                        <PosterPlaceholder>
                            No poster available
                        </PosterPlaceholder>
                    )}
                </PosterWrapper>
            </Section>
        </EventPageWrapper>
    );
};

export const query = graphql`
    query GetEventPage($uid: String!) {
        prismic {
            event(lang: "en-au", uid: $uid) {
                date_start
                date_end
                info
                lineup
                location_address
                location_title
                links {
                    link_url {
                        ... on PRISMIC__ExternalLink {
                            url
                        }
                    }
                    link_title
                }
                poster_image
                poster_imageSharp {
                    childImageSharp {
                        fluid {
                            srcSet
                            src
                        }
                    }
                }
                tickets_link {
                    ... on PRISMIC__ExternalLink {
                        url
                    }
                }
                title
            }
        }
    }
`;

export default EventPage;
