import styled from 'styled-components';
import colors from '@shared/colors';
import {
    generateVwPercentageHeight,
    generateVwPercentageWidth,
} from '@src/helpers';
import { generateBreakpoint, generateGrid } from '@layouts/grid';

export const EventPageWrapper = styled.div`
    padding-bottom: 28px;

    ${generateBreakpoint('lg')(
        `${generateGrid(12, 0)}
            grid-auto-rows: minmax(min-content, max-content);
            section.sec-poster {
                grid-row: 1 / 7;
                grid-column: 1 / 5;
                margin: 0;
                border: 0;
            }
            section.sec-title,
            section.sec-date,
            section.sec-location,
            section.sec-links,
            section.sec-tickets,
            section.sec-dummy {
                grid-column: 5 / 9;
            }
            section.sec-lineup,
            section.sec-info {
                grid-column: 9 / 13;
                border-right: 0;
            }

            section.sec-lineup {
                grid-row: 2 / 8;
                border-bottom: 0;
            }

            section.sec-info {
                grid-row: 1 / 2;
            }

            section.sec-dummy {
                display: block;
                height: 10vw;
                grid-row: 6 / 7;
                border-bottom: 0;
            }

            padding-bottom: 0;
        `,
    )}
`;

export const PosterPlaceholder = styled.div`
    border: solid 1px #707070;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.brownGrey};
    height: 100%;
    width: 100%;
    font-size: 14px;
    line-height: 1.21;
    letter-spacing: 0.28px;
`;

export const PosterWrapper = styled.div`
    margin: 0 12px;
    width: calc(100vw - 24px);
    ${generateVwPercentageHeight(371, 556, 320)}

    ${generateBreakpoint('lg')(
        `${generateVwPercentageWidth(476, 714)}
        ${generateVwPercentageHeight(678, 1017)}
        margin: 0;`,
    )}
`;

export const SectionHeader = styled.h3`
    padding: 10px 12px;
    background-color: ${colors.brownGrey};

    font-family: 'SuisseIntl';
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.24px;
    color: ${colors.violetBlue};
    text-transform: uppercase;

    ${generateBreakpoint('lg')(
        `font-size: 14px;
        line-height: 1.29;
        letter-spacing: 0.28px;
        background-color: transparent;
        color: ${colors.brownGrey};

        border-bottom: 1px solid ${colors.brownGrey};`,
    )}
`;

export const Section = styled.section`
    &.sec-dummy {
        display: none;
    }

    ${generateBreakpoint('lg')(
        `margin-left: -1px;
        border-right: 1px solid ${colors.brownGrey};
        border-left: 1px solid ${colors.brownGrey};
        border-bottom: 1px solid ${colors.brownGrey};`,
    )}
`;

export const SectionContent = styled.div`
    padding: 9px 12px;
`;

export const TitleWrapper = styled.div`
    h1 {
        font-size: 28px;
        line-height: 1.25;
        letter-spacing: -0.84px;
        text-transform: uppercase;
        word-break: break-word;

        ${generateBreakpoint('lg')(
            `font-size: 72px;
            margin-top: 5px;
            font-weight: 500;
            line-height: 0.83;
            letter-spacing: -2.16px;`,
        )}
    }
`;

export const DateWrapper = styled.div`
    font-size: 28px;
    line-height: 1.14;
    letter-spacing: -0.84px;

    ${generateBreakpoint('lg')(
        `font-size: 40px;
        line-height: 1.1;
        letter-spacing: -1.2px;`,
    )}
`;

export const LocationTitle = styled.div`
    h3 {
        font-size: 28px;
        line-height: 1.25;
        letter-spacing: -0.84px;
        text-transform: uppercase;

        ${generateBreakpoint('lg')(
            `font-size: 40px;
            line-height: 1.3;
            letter-spacing: -1.2px;`,
        )}
    }

    margin-bottom: 8px;
`;

export const LocationAddress = styled.div`
    font-size: 12px;
    line-height: 1.42;
    letter-spacing: -0.24px;
    text-transform: uppercase;

    ${generateBreakpoint('lg')(
        `font-size: 14px;
        line-height: 1.71;
        letter-spacing: -0.28px;`,
    )}
`;

export const Lineup = styled.div`
    &,
    a,
    a:visited,
    a:active {
        font-size: 28px;
        line-height: 1.14;
        letter-spacing: -0.84px;
        text-transform: uppercase;

        ${generateBreakpoint('lg')(
            `font-size: 40px;
            line-height: 1.1;
            letter-spacing: -1.2px;`,
        )}
    }
`;

export const LinkIcon = styled.div`
    display: inline-block;
    margin-right: 8px;
`;

export const Links = styled.div`
    text-transform: uppercase;

    &,
    a,
    a:visited,
    a:active {
        font-size: 12px;
        line-height: 1.42;
        letter-spacing: -0.24px;

        ${generateBreakpoint('lg')(
            `font-size: 14px;
            line-height: 1.71;
            letter-spacing: -0.28px;`,
        )}
    }
`;

export const Tickets = styled.div`
    text-transform: uppercase;

    &,
    a,
    a:visited,
    a:active {
        text-transform: uppercase;
        font-size: 12px;
        line-height: 1.42;
        letter-spacing: -0.24px;

        ${generateBreakpoint('lg')(
            `font-size: 14px;
            line-height: 1.71;
            letter-spacing: -0.28px;`,
        )}
    }
`;

export const InfoWrapper = styled.div`
    &,
    a,
    a:visited,
    a:active {
        font-family: 'SuisseIntl';
        font-size: 12px;
        line-height: 1.42;
        letter-spacing: 0.18px;

        ${generateBreakpoint('lg')(
            `font-size: 14px;
            line-height: 1.21;
            letter-spacing: 0.21px;`,
        )}
    }
    padding-bottom: 18px;

    p {
        margin-bottom: 1em;
    }
`;
